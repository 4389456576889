import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Head from 'next/head'

const image = require('../assets/images/backgrounds/contact-form-background.png')

const SEOHelmet = ({ pageMetadata }) => {
	return (
		<Head>
			<meta charSet='utf-8' />
			<link rel='canonical' href={ get(pageMetadata, 'url') } />

			<title>{ get(pageMetadata, 'title', 'Bratislavskí susedia') }</title>
			<meta name={ 'description' } content={ get(pageMetadata, 'description') } />
			<meta
				name={ 'keywords' }
				content={ get(pageMetadata, 'keywords', 'Bratislava, Bratislavskí susedia, Eko, 10 000 stromov, komunitné záhrady') }
			/>

			<meta
				property={ 'og:url' }
				content={ get(pageMetadata, 'ogUrl') }
			/>
			<meta
				property={ 'og:title' }
				content={ get(pageMetadata, 'ogTitle') }
			/>
			<meta
				property={ 'og:description' }
				content={ get(pageMetadata, 'ogDescription') }
			/>
			<meta
				property={ 'og:image' }
				content={ get(pageMetadata, 'ogImage') || image }
			/>
			<meta
				property={ 'og:locale' }
				content={ 'sk_SK' }
			/>

			<meta
				property={ 'og:type' }
				content={ get(pageMetadata, 'type') || 'article' }
			/>
		</Head>
	)
}

SEOHelmet.propTypes = {
	pageMetadata: PropTypes.shape({})
}

SEOHelmet.defaultProps = {
	pageMetadata: {}
}

export default SEOHelmet
